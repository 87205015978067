/* Font icons */
$fontName: 'icons';
$fontPath: '../fonts/icons';
$fontVersion: '123';

// Declaration
@font-face {
    font-family: $fontName;
    src:url($fontPath + '.eot?' + $fontVersion);
    src:url($fontPath + '.eot?#iefix' + $fontVersion) format('embedded-opentype'),
        url($fontPath + '.woff?' + $fontVersion) format('woff'),
        url($fontPath + '.ttf?' + $fontVersion) format('truetype'),
        url($fontPath + '.svg?' + $fontVersion + '#' + $fontName) format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icons {
    display: inline-block;
    font-family: $fontName;
    font-size: 17px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 19px;
    speak: none;
    text-align: center;
    text-decoration: none !important;
    text-transform: none;
    vertical-align: -3px;
    width: 1em;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Font rules
[class^="icon--"]:before,
[class*=" icon--"]:before,
.icons,
%icons{
    @include icons;
}


.icon--addtolist:before { content: "\0032"; }
.icon--addtorecipebox:before { content: "\0031"; }
.icon--arrow-left:before { content: "\0035"; }
.icon--arrow-right:before { content: "\0036"; }
.icon--arrow-skinny-left:before { content: "\0037"; }
.icon--arrow-skinny-right:before { content: "\0038"; }
.icon--arrow-up,
%icon--arrow-up {
    &:before {
        content: "\0034"; text-indent:-2px;
    }
}
.icon--arrow-down,
%icon--arrow-down {
    &:before {
        content: "\0033"; text-indent:-2px;
    }
}
.icon--cart-fee:before { content: ":"; }
.icon--cart:before { content: "\0039"; }
.icon--check,
%icon--check {
    &:before { 
        content: "\003c"; 
    }
}
.icon--comments:before { content: "\003b"; }
.icon--delete-circle:before { content: "\003d"; }
.icon--delete:before { content: "\005d"; }
.icon--delivery:before { content: "\003e"; }
.icon--delivery-fee:before { content: "\003f"; }
.icon--doubelarrow-skinny-left:before { content: "\0040"; }
.icon--doubelarrow-skinny-right:before { content: "\0041"; }
.icon--edit:before { content: "\0042"; }
.icon--facebook-circle:before,
.icon--facebook:before { content: "\0043"; }
.icon--flag:before { content: "\0044"; }
.icon--flyer:before { content: "\0045"; }
.icon--square:before { content: "\0046"; }
.icon--googleplus-circle:before,
.icon--googleplus:before { content: "\0047"; }
.icon--help:before { content: "\0048"; }
.icon--hours:before { content: "\0049"; }
.icon--location:before { content: "\005b"; }
.icon--mail:before { content: "\004a"; }
.icon--menu:before { content: "\004b"; }
.icon--minus:before { content: "\005c"; }
.icon--notice:before { content: "\004d"; }
.icon--phone:before { content: "\004f"; }
.icon--pickup:before { content: "\0050"; }
.icon--pinterest-circle:before,
.icon--pinterest:before { content: "\0052"; }
.icon--play-circle:before { content: "\0053"; }
.icon--play-default:before { content: "\0054"; }
.icon--plus:before { content: "\0055"; }
.icon--erlenmeyer:before { content: "\0056"; }
.icon--pig:before { content: "\0051"; }
.icon--calendar:before { content:"\0057"; }
.icon--print:before { content: "\0058"; }
.icon--readmode:before { content: "\005f"; text-indent:-2px;}
.icon--search:before { content: "\0059"; }
.icon--share:before { content: "\0061"; }
.icon--save:before { content: "\0065"; }
.icon--smile:before { content: "\005a"; }
.icon--subscribe:before { content: "\005c"; }
.icon--twitter-circle:before,
.icon--twitter:before { content: "\005e"; }
.icon--uncheck:before { content: "\003d"; }
.icon--user:before { content: "\0030"; }
.icon--warning:before { content: "\002e"; }
.icon--warning-circle:before { content: "\002f"; }
.icon--cash:before { content: "\0063"; }
.icon--fax:before { content: "\0064"; }
.icon--close:before { content: "\0062"; }
.icon--error:before { content: "\003d"; }


.icon--alt:before {
    color: $color-main;
    background-color: $color-lime-green;
}

.icon--large:before {
    font-size: 16px;
    line-height: 30px;
}

.icon--larger:before {
    font-size: 26px;
    line-height: 30px;
}

.icon--small,
%icon--small {
    &:before {
        font-size: 10px !important;
        line-height: 21px;
    }
}

.icon--muted {
    color: $color-muted;
}

.icon--text {
    @include border-radius(15px);
    background: $color-main;
    display: inline-block;
    padding-right: 5px;
    color: $color-main;
    line-height: 30px;
}

// spacing for the icon and icon label
// if the label is at the right of the icon
.icon + .icon-label {
    margin-left: $spacing;
}
// if the label is at the left of the icon
.icon-label + .icon {
    margin-left: $spacing;
}

.icon-group {
    margin-top: $spacing;

    > :first-child {
        margin-left: 0;
    }
    > * {
        margin-left: $spacing-double;
    }

    .icon-label {
        vertical-align: middle;
    }
}



