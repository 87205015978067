.btn,
.text .btn {
  @include font-size(15px, 24px);
  @extend %font-brand-bolder;
  // border-radius: 5px;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 4px 9px;
  border: solid 1px transparent;
  background: transparent;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;

  &:focus {
    outline: 0;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &--default,
  &--action,
  &--light.is-active {
    background: $color-main;
    border-color: $color-main;
    color: #fff;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      // color: darken(#fff, 10%);
      // background: darken($color-main, 10%);
      // border-color: darken($color-main, 10%);
      color: $color-warm-black;
      background: $color-maize;
      border-color: $color-maize;
    }
  }

  &--variant {
    background: $color-secondary;
    border-color: $color-secondary;
    color: #fff;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      color: darken(#fff, 10%);
      background: darken($color-secondary, 10%);
      border-color: darken($color-secondary, 10%);
    }
  }

  &--hollow,
  &--light,
  &--blank {
    color: $color-main;
    border-color: $color-main;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background-color: $color-secondary;
    }
  }

  &--hollow-variant {
    color: #fff;
    border-color: #fff;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      color: darken(#fff, 10%);
      border-color: darken(#fff, 10%);
    }
  }

  &--important {
    text-transform: uppercase;
    background: $color-berry;
    border-color: $color-berry;
    color: #fff;
    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background: darken($color-berry, 10%);
      border-color: darken($color-berry, 10%);
      color: darken(#fff, 10%);
    }
  }
  &--large {
    padding: 9px 14px;
  }

  &--x-large {
    @include font-size(21px, 24px);
    padding: 17px 19px;
  }

  // todo: more DRY
  &--alt.is-active {
    background: $color-dark-gray;
    color: #fff;
  }

  &--muted {
    background: $color-gray;
    border-color: $color-gray;
    color: #fff;
    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background: #fff;
      border-color: $color-gray;
      color: #000;
    }
  }

  &--dark {
    background: #000;
    border-color: #000;
    color: $color-light-gray;
    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background: #fff;
      border-color: $color-gray;
      color: #000;
    }
  }
  &--discreet {
    background: $color-very-light-gray;
    border-color: $color-very-light-gray;
    color: $color-text;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background: $color-very-light-gray;
      border-color: $color-very-light-gray;
      color: $color-main;
    }
  }

  &--danger {
    background: $color-very-light-gray;
    border-color: $color-very-light-gray;
    color: $color-text;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      background: $color-red;
      border-color: $color-red;
      color: #fff;
    }
  }

  &--minwidth {
    min-width: 170px;
  }

  &--full {
    min-width: 100%;
  }

  &.is-disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      color: #fff;
    }
  }

  &--text {
    color: $color-text;
    background: transparent;
    border: 0;

    &:hover,
    &:focus,
    a:hover &,
    a:focus & {
      color: $color-main;
    }
  }
  &--icon {
    .icon {
      margin-right: 5px;
    }
  }
  &--icon-large {
    @include font-size(16px, 30px);
  }
}

.btn--show-more {
  display: none;
  font-weight: normal;
  @include font-size(12px);

  .is-active & {
    display: inline-block;
  }
}

.btn-group {
  margin-top: $spacing;

  > :first-child {
    margin-left: 0;
  }
  > * {
    margin-left: 3px;
  }
}
