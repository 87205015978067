.tiles {
    &__list {
        margin-bottom: -$spacing-double;
    }
    
    &__tile {
        list-style: none;
        padding-bottom: $spacing-double;


        &.is-empty {
            @include grid-media-query("palm") {
                display: none;
            }
        }
    }

    &__content {
        background-color: white;
        display: block;
        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }


    &__label {
        box-sizing: border-box;
        color: $color-warm-black;
        font-weight: 900;
        padding: $spacing-large $spacing-x-large;
        width: 100%;
    }
}
