.databox {
    @extend %clearfix;
    color: $color-warm-black;
    margin: -$spacing-half;

    &__item {
        box-sizing: border-box;
        float: left;        
        padding: $spacing-half;
        text-align: center;
        width: 25%;
    }

    &__value,
    &__desc {
        border: 1px solid $color-main;
        padding: $spacing-half;
    }

    &__value {
        @include font-size(15px, 17px);
        border-bottom: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-weight: bold;
        padding-bottom: 0;
    }

    &__desc {
        @include font-size(13px, 15px);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: none;
        padding-top: 0;
    }
}