
.cart-item + .cart-item {
    border-top: 1px dotted $color-main;
}


.cart-summary {
    max-height: 400px;
    overflow-y: auto;

    &__item {
        position: relative;
        padding: $spacing;
        color: $color-warm-black;

        & + & {
            border-top: solid 1px $color-light-gray;
        }
        
        > table {
            table-layout: fixed;

            td {
                word-wrap: break-word;
            }
        }

    }

    &__quantity {
        width: 16%;
        input {
            @include font-size(15px, 32px);
            border: 1px solid $color-main;
            font-weight: bold;
            padding: 0;
            width: 33px;
            height: 32px;
        }
    }
    
    &__price {
        width: 20%;
        text-align: right;
        font-weight: 900;
    }

    &__actions {
        text-align: right;
        width: 12%;
        > .btn {
            padding: 0;
            > .icon--delete {
                color: $color-main;

                &:hover,
                &:focus {
                    color: darken($color-main, 10%);
                }
            }
        }
    }
}
