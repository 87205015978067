// =Layout Custom Styles
// --------------------------------------------------

// Overrides / additional styles for the R&P site

$logo-height: 55px;

.secondary-nav__customer-service {
  display: inline;
}

.logos a {
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.logo {
  display: block;
  float: left;
  margin-right: 2em;
}

.logos__text {
  @extend .h1-like;
  @include font-size(32px, 32px);
  padding-bottom: 0;
  white-space: nowrap;

  display: block;
  float: left;
  margin-top: ($logo-height - 32px) / 2;

  @include grid-media-query(portable-up) {
    @include font-size(48px, 48px);
    margin-top: ($logo-height - 48px) / 2;
  }
}

// displays when on a build other than live
.build {
  // border: 1px solid #000;
  color: red;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 215px;
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;

  position: absolute;
  top: 0;
  left: 0;
}

@include grid-media-query(palm) {
  .mini-cart {
    float: right;
  }
}

.mini-cart__label {
  border: 0;
  color: $color-black;
}

.mini-cart__icon {
  // height and padding have to add to up 40px
  height: 24px;
  padding: 8px 5px;
  margin: 0;
  display: inline-block;
}

// some main nav mods moved to pickup/main-nav

.main__content {
  padding-bottom: 2em;
}

.banner__media {
  background-color: #fff;
}

// youtube isn't in the icon font so we have to add it manually

// .main-footer .social-share .icon--youtube-circle {
//   background-image: url(../images/icon-youtube.png);
//   background-position: center center;
//   background-repeat: no-repeat;
// }

.main-footer .social-share .social-share__link {
  background: transparent;
  // border-radius: 50%;
  // display: inline-block;
  // height: 36px;
  // margin-left: 3px;
  // text-align: center;
  // vertical-align: middle;
  // width: 36px;
  height: 42px;
  width: 42px;
}

.main-footer .social-share .social-share__icon {
  height: 42px;
  width: 42px;
  // border: 1px solid #000;
}

// forms

.pickup-form .required {
  @include hide();
  @include clearfix();
}

.pickup-form .validation-error .validation {
  @include font-size(14px);
  color: $color-red;
  vertical-align: top;
  font-weight: bold;
}

.pickup-form .required-message {
  // padding-left: 20px;
}

.pickup-form input[type='submit'] {
  // float: right;
  // margin-right: 0;
  margin-top: 1em;
}

input[type='number'].club-number {
  text-align: left !important;
  padding-left: $spacing !important;
  padding-right: $spacing !important;
}

// text overrides

.text ul li:before {
  font-size: inherit;
}

// Tables -- override default styles by request

table tr:hover td {
  color: inherit !important;
}
