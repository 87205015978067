
/*------------------------------------*\
    $LINK-COMPLEX
\*------------------------------------*/
/**
 * As inspired by @necolas:
 * github.com/necolas/suit-utils/blob/master/link.css#L18
 *
 * Add hover behaviour to only selected items within links, e.g.:
 *
   <a href=log-in class=link-complex>
       <i class="s  s--user"></i>
       <span class=link-complex__target>Log in</span>
   </a>
 *
 * Demo: jsfiddle.net/inuitcss/rt9M3
 *
 */
.link-complex,
.link-complex:hover,
.link-complex:active,
.link-complex:focus {
    text-decoration: none;
}

.link-complex .link-complex__target {
    border-bottom: solid 1px;
}

