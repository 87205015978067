@mixin accessibility{
    border: 0!important;
    clip: rect(0 0 0 0)!important;
    height: 1px!important;
    margin: -1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important;
}

a.is-disabled,
a[disabled] {
    pointer-events: none;
    cursor:default;

    &:hover{
        color:inherit;
    }
}

.full {
    width:100%;
}


// When it has background color and we want it to be full height
// needs this because the grid items are inline-block mainly
@include grid-media-query("portable-up") {
    .fill-height {
        padding-bottom: 100000px;
        margin-bottom: -100000px;
    }
}


.is-clickable {
    cursor: pointer;
}

.accessibility,
.visuallyhidden {
    @include accessibility;
}

.hidden {
    display: none !important;
}

.relative {
    position:relative;
}

.block {
    display:block;
}

.invisible {
    opacity:0 !important;
}

.opacity-transition {
    transition:opacity 0.5s ease;
}

.float--left {
    float: left;
}
.float--right {
    float: right;
}
.inline-block {
    display: inline-block;
}

.no-border {
    border:0 !important;
}

.cover--white {
    background:rgba(255,255,255,0.3);
}

.no-overflow {
    overflow: hidden;
}

.print .print--hide {
    display: none !important;
}


/**
* Alignments
*/
.text--left {
    text-align: left !important;
}
.text--center {
    text-align: center !important;
}
.text--right {
    text-align: right !important;
}

.palm--text--left{
    @include grid-media-query("palm") {
        text-align: left !important;
    }
}
.palm--text--center{
    @include grid-media-query("palm") {
        text-align: center !important;
    }
}
.palm--text--right{
    @include grid-media-query("palm") {
        text-align: right !important;
    }
}

.palm--fluid {
     @include grid-media-query("palm") {
        width:100% !important;
    }
}

.palm--block {
     @include grid-media-query("palm") {
       display: block !important;
    }
}


/**
* Letter Cases
*/
.text--emphasis {
    text-transform: uppercase !important;
}

.image--readmode {
    width:220px;
}

.palm-sized {
    max-width:480px;
}

.sticker {
    position:absolute;
    right:$spacing-and-half;
    top:$spacing-and-half;
    z-index:2;
}

/**
 * Add/remove margins
 */
.push { margin: $spacing !important; }
.push--top { margin-top: $spacing !important; }
.push--right { margin-right: $spacing !important; }
.push--bottom { margin-bottom: $spacing !important; }
.push--left { margin-left: $spacing !important; }
.push--ends { margin-top: $spacing !important; margin-bottom: $spacing !important; }
.push--sides { margin-right: $spacing !important; margin-left: $spacing !important; }

.push-medium { margin: $spacing-double !important; }
.push-medium--top { margin-top: $spacing-double !important; }
.push-medium--right { margin-right: $spacing-double !important; }
.push-medium--bottom { margin-bottom: $spacing-double !important; }
.push-medium--left { margin-left: $spacing-double !important; }
.push-medium--ends { margin-top: $spacing-double !important; margin-bottom: $spacing-double !important; }
.push-medium--sides { margin-right: $spacing-double !important; margin-left: $spacing-double !important; }

.push-large { margin: $spacing-triple !important; }
.push-large--top { margin-top: $spacing-triple !important; }
.push-large--right { margin-right: $spacing-triple !important; }
.push-large--bottom { margin-bottom: $spacing-triple !important; }
.push-large--left { margin-left: $spacing-triple !important; }
.push-large--ends { margin-top: $spacing-triple !important; margin-bottom: $spacing-triple !important; }
.push-large--sides { margin-right: $spacing-triple !important; margin-left: $spacing-triple !important; }

.push-half { margin: $spacing-half !important; }
.push-half--top { margin-top: $spacing-half !important; }
.push-half--right { margin-right: $spacing-half !important; }
.push-half--bottom { margin-bottom: $spacing-half !important; }
.push-half--left { margin-left: $spacing-half !important; }
.push-half--ends { margin-top: $spacing-half !important; margin-bottom: $spacing-half !important; }
.push-half--sides { margin-right: $spacing-half !important; margin-left: $spacing-half !important; }

.flush { margin: 0 !important; }
.flush--top { margin-top: 0 !important; }
.flush--right { margin-right: 0 !important; }
.flush--bottom { margin-bottom: 0 !important; }
.flush--left { margin-left: 0 !important; }
.flush--ends { margin-top: 0 !important; margin-bottom: 0 !important; }
.flush--sides { margin-right: 0 !important; margin-left: 0 !important; }

/**
 * Add/remove paddings
 */
.soft { padding: $spacing !important; }
.soft--top { padding-top: $spacing !important; }
.soft--right { padding-right: $spacing !important; }
.soft--bottom { padding-bottom: $spacing !important; }
.soft--left { padding-left: $spacing !important; }
.soft--ends { padding-top: $spacing !important; padding-bottom: $spacing !important; }
.soft--sides { padding-right: $spacing !important; padding-left: $spacing !important; }

.soft-half { padding: $spacing-half !important; }
.soft-half--top { padding-top: $spacing-half !important; }
.soft-half--right { padding-right: $spacing-half !important; }
.soft-half--bottom { padding-bottom: $spacing-half !important; }
.soft-half--left { padding-left: $spacing-half !important; }
.soft-half--ends { padding-top: $spacing-half !important; padding-bottom: $spacing-half !important; }
.soft-half--sides { padding-right: $spacing-half !important; padding-left: $spacing-half !important; }

.soft-medium { padding: $spacing-double !important; }
.soft-medium--top { padding-top: $spacing-double !important; }
.soft-medium--right { padding-right: $spacing-double !important; }
.soft-medium--bottom { padding-bottom: $spacing-double !important; }
.soft-medium--left { padding-left: $spacing-double !important; }
.soft-medium--ends { padding-top: $spacing-double !important; padding-bottom: $spacing-double !important; }
.soft-medium--sides { padding-right: $spacing-double !important; padding-left: $spacing-double !important; }

.soft-large { padding: $spacing-triple !important; }
.soft-large--top { padding-top: $spacing-triple !important; }
.soft-large--right { padding-right: $spacing-triple !important; }
.soft-large--bottom { padding-bottom: $spacing-triple !important; }
.soft-large--left { padding-left: $spacing-triple !important; }
.soft-large--ends { padding-top: $spacing-triple !important; padding-bottom: $spacing-triple !important; }
.soft-large--sides { padding-right: $spacing-triple !important; padding-left: $spacing-triple !important; }

.hard { padding: 0 !important; }
.hard--top { padding-top: 0 !important; }
.hard--right { padding-right: 0 !important; }
.hard--bottom { padding-bottom: 0 !important; }
.hard--left { padding-left: 0 !important; }
.hard--ends { padding-top: 0 !important; padding-bottom: 0 !important; }
.hard--sides { padding-right: 0 !important; padding-left: 0 !important; }

/**
 * Pull items full width of `.island` parents.
 */
.full-bleed {
    margin-right: -$spacing!important;
    margin-left: -$spacing!important;

    .soft &,
    .soft--sides & { margin-left: -$spacing !important; margin-right: -$spacing !important; }
    .soft-medium &,
    .soft-medium--sides & { margin-left: -$spacing-double !important; margin-right: -$spacing-double !important; }
    .soft-large &,
    .soft-large--sides & { margin-left: -$spacing-triple !important; margin-right: -$spacing-triple !important; }
    .soft-half &,
    .soft-half--sides & { margin-left: -$spacing-half !important; margin-right: -$spacing-half !important; }
}



/**
 * Layers
 * For quick and easy z-index layering
 */
@for $i from 1 through 10 {
    .layer-#{$i} {
        position: relative;
        z-index: $i;
    }
}
