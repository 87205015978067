.stepper {
    background: url(../images/thriftyfoods-light-background.png) #fff;
    position: relative;
    z-index: 2;

    ul {
        display: table;
        width: 100%;
        position: relative;
        padding-top: 25px;
        padding-bottom: 20px;
        table-layout: fixed;
    }
    &:before {
        background: $color-main;
        content: "";
        display: block;
        position: absolute;
        left: 0; top: 55px;
        width: 100%; height: 4px;
    }
    li {
        @extend %font-brand-bold;
        text-align: center;
        display: table-cell;
        position: relative;
        padding-top: 55px;
        vertical-align: top;

        &:before {
            background: #fff;
            border-radius: 12px;
            border: solid 4px $color-main;
            box-sizing: border-box;
            content: "";
            display: block;
            left: 50%;
            margin: 0 auto $spacing auto;
            position: absolute;
            top: 20px;
            transform: translateX(-50%);
            width: 22px; height: 22px;
        }
        &.is-active:before {
            background: $color-secondary;
            border-radius: 14px;
            top: 17px;
            width: 28px; height: 28px;
        }
    }
}

/**
 * Stepper that looks like darts
 * step > step > step >
 */
.stepper--dart {
    display: table;
    table-layout: fixed;
    width: 95%;

    li {
        display: table-cell;

        @extend %font-brand-bold;
        @include font-size(18px);
        text-align: center;
        position: relative;
        height: 50px;
        line-height: 50px;
        background: $color-very-light-gray;
        border: solid 1px $color-gray;

        &.is-active {
            background: $color-lite-lime-green;

            &:after {
                border-left-color: $color-lite-lime-green;
            }
        }

        &:after {
            content: "";
            position: absolute;
            right: -25px;
            width: 0; height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 25px solid $color-very-light-gray;
            z-index: 11;
        }
        &:before {
            content: "";
            position: absolute;
            right: -26px;
            width: 0; height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 25px solid $color-gray;
            z-index: 10;
        }
    }
}
