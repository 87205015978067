.search-box {
    @include font-size(13px);

    &__input {
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        text-align: left;
        padding-right: 45px;

        .tt-init & {
            overflow: visible; // fix for twitter typeahead wrapping it in a useless span
        }
        .twitter-typeahead { display: block !important; }

        .btn {
            background-color: $color-tf-dark-green;
            border-radius: 0;
            box-sizing: border-box;
            color: $color-tf-light-green;
            height: 40px;
            width: 45px;
            position: absolute;
            right: 0;
            top: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            opacity: 1;

            span {
                display: block;
                margin-top: -4px;
            }
            &[disabled="disabled"] {
                opacity: 0.8;
            }
        }

        button.btn {
            line-height:normal;
        }
    }

    // added with js because of stupid twitter typeahead
    .tt-search-field-container {
        display: block;
        overflow: hidden;
    }
    .search-field {
        border-radius: 0;
        border: 1px solid #fff;
        box-sizing: border-box;
        height: 40px;
        width: 100%;
    }
    .tt-dropdown-menu {
        position: absolute;
        background: #fff;
        border: solid 1px $color-warm-gray;
        border-top: 0;
        box-sizing: border-box;
        padding: $spacing-half 0;
        width: 100%;

        .tt-suggestion span {
            display: block;
            padding: $spacing-half $spacing;
            transition: background .15s ease-out;
        }
        .tt-suggestion span strong {
            color: $color-main;
        }
        .tt-suggestion.tt-cursor {
            background: $color-light-gray;
            color: $color-main;
        }
    }
}

.dropdown {
    position: relative;

    &__cta {
        @include font-size(13px, 40px);
        background-color: white;
        box-sizing: border-box;
        color: $color-warm-black;
        cursor: pointer;
        display: block;
        height: 40px;
        padding: 0 $spacing-large;
        position: relative;
        white-space: nowrap;
        border-right: 1px solid $color-warm-grey;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        span {
            display: block;
            float: left;
        }
        &:before {
            color: $color-tf-dark-green;
            @extend %icons;
            @extend %icon--small;
            @extend %icon--arrow-down;

            font-size: inherit; // because of the "hack" of extending the icons class we reset the font-size to inerit it's parent
            padding-left: 12px;
            padding-top: 8px;
            float: right;
        }
    }

    &__content {
        background: #fff;
        border: solid 1px $color-warm-gray;
        display: none;
        margin-top: -1px;
        position: absolute;
        top: 100%;

        .is-active & {
            display: block;
        }
    }

    li label {
        display: block;
        cursor: pointer;
        padding: $spacing $spacing-double;
        position: relative;
    }

    li input[type="radio"]:focus + label,
    li label:hover {
        background: $color-main;
        color: #fff;
        &:before {
            color: #fff !important;
        }
    }


    li input[type="radio"] {
        position: absolute;
        width: 0;
        opacity: 0;
    }
    li input[type="radio"].checked + label,
    li.is-active label {
        &:before {
            // we include directly the mixin of the icons
            // because our selector is a too complicated and fails in IE if we extend the icons class
            @include icons;
            @extend %icon--small;
            @extend %icon--check;

            display: block;
            position: absolute;
            color: $color-main;
            background: transparent;
            border: 0;
            box-shadow: none;
            cursor: pointer;
            height: auto;
            top: $spacing;
            left: $spacing-half;
            width: auto;
        }
    }
}


.locator-map {
    position:relative;

    .locator-map__fields {
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        z-index:2;
    }
}
