.section {
    &__content {
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;

        // minus 15px is the width of the scrollbar
        // this might not the best long term solution
        @include grid-media-query('portable') {
            //width: ($portable - 15px);
        }
        @include grid-media-query('desk') {
            width: ($desk - 15px);
        }
    }
}

.content {
    padding: 0 $gutter;
}


.main-header {
    position: relative;
    z-index: 21;

    &__content {
        padding: 0 20px;

        @include grid-media-query('portable-up') {
            padding: 30px 20px;
        }

        @include grid-media-query('desk') {
           padding: 30px 0;
        }
    }

    &__cart {
        @include grid-media-query('palm') {
            height: 40px;
        }
        @include grid-media-query('portable-up') {
            text-align: right;
        }
    }

    &__controls {
        @include grid-media-query('palm') {
            color: $color-secondary;
            background-color: $color-main;
            line-height: 50px;
            height: 50px;
            width: auto;
            display: block;
            margin-right: -$gutter;
            margin-top: $spacing-large;

            > .grid > .grid__item {
                vertical-align: middle;
            }

            a:hover,
            a:focus {
                color: white;
            }
        }
    }

    // toggle for the mobile MENU button

    .is-open {
        .to-show {
            display: inline-block;
        }
        .to-hide {
            display: none;
        }
    }

    .to-show {
        display: none;
    }
    .to-hide {
        display: inline-block;
    }
}

.main-footer {
    overflow: hidden;

    &__content {
        &--header,
        &--fat-nav {
            padding: $spacing-double 0;

            @include grid-media-query('portable-up') {
                padding: $spacing-triple 0;
            }
        }

        &--header {
            background: $color-main;
            text-align: center;

            @include grid-media-query('portable-up') {
                text-align: left;
            }

            &,
            a {
                color: $color-secondary;
            }
        }

        &--fat-nav {
            background-color: #fff;
            box-sizing: border-box;
            @include grid-media-query('portable-up') {
                background: url(../images/footer-bg.jpg) no-repeat top right #fff;
                height: 261px;
            }
        }
    }

    &__links {
        a {
            @include font-size(15px, 24px);
            color: $color-main;
            font-weight: bolder;
        }
    }
}
