// =Store
// --------------------------------------------------

// choose store

.select-store-form {

  .store-table {
    max-width: 900px;
    margin-bottom: 2em;
  }

  .store-table tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  .store-table .unavailable {
    color: #909090;
  }

  .store-table td {
    // vertical-align: top;
  }

  .store-table .item-input input[type=radio] {
    vertical-align: middle;
  }

  .store-table tfoot td {
    padding-left: 22px;
  }

  @include grid-media-query(portable-up) {
    .store-table .item-input {
      padding-left: 10px !important;
      padding-right: 5px !important;
      width: 15px !important;
    }
    .store-table .item-store {
      padding-left: 0 !important;
    }
  }

  label {
    cursor: pointer;
  }

  .unavailable label {
    cursor: default;
  }

  h2 {
    margin-bottom: 0.5em;
  }

  .pickup-time-container {
    list-style: none;
    margin: 0;
    padding: 0 !important;

    li:before {
        display:none;
    }
  }

  @include grid-media-query(palm) {
    .pickup-time {
      margin-top: 2em;
    }
  }

  .store-notes {
    margin-top: 2em;
  }

  .store-notes textarea {
    width: 100%;
    height: 10em;
  }

  .submit {
    margin-top: 2em;
  }

  .alternate-cities {
    clear: both;
    margin-top: 2em;
  }

}
