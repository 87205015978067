// Custom radio buttons and checkboxes

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fluid-input {
  input,
  select,
  textarea {
    width: 100%;
  }
  overflow: hidden;
}

input,
select,
textarea {
  &.fixed-input,
  .fixed-input & {
    width: 300px !important;
  }
}

input,
textarea,
select {
  border: 1px solid $color-maize;
  &.fluid {
    width: 100%;
  }
  @include font-size(13px);
}

textarea {
  border: 1px solid $color-maize;
  box-sizing: border-box;
  resize: none;
  // border-radius: 5px;
  padding: $spacing;
  display: block;

  &.is-error {
    border-color: $color-red;
  }
  &:focus {
    border: solid 1px $color-main;
    outline: none;
  }
}

select {
  border: solid 1px $color-maize;

  padding: 10px;
  box-sizing: border-box;
  // border-radius: 5px;
  max-width: 100%;

  &.is-error {
    border-color: $color-red;
  }

  &:focus {
    border: solid 1px $color-main;
    outline: none;
  }
  @include grid-media-query('palm') {
    font-size: 16px;
  }
}

input[type='text'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'] {
  // border-radius: 5px;
  box-sizing: border-box;
  padding: 9px $spacing;
  vertical-align: middle;
  line-height: 20px;

  // webkit default styling
  -webkit-appearance: none;

  // to prevent zoom on ios
  @include grid-media-query('palm') {
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  width: 40px;
}

.numeric input {
  @include font-size(15px, 32px);
  border: 1px solid $color-main;
  color: $color-warm-black;
  font-weight: bold;
  padding: 0;
  width: 34px;
  height: 34px;
}

.checkbox,
.radio {
  input[type='radio'] + label,
  input[type='checkbox'] + label {
    cursor: pointer;
    vertical-align: bottom;
    padding-left: $spacing-triple;
    display: block;
    position: relative;

    &:focus:before {
      background: $color-main;
    }
  }

  // pseudo element that will take the control's place
  input[type='radio'] + label:before,
  input[type='checkbox'] + label:before {
    background: #fff;
    border: solid 1px $color-warm-grey;
    box-shadow: inset 0 0 0px 2px #fff;
    content: '';
    cursor: pointer;
    display: inline-block;
    height: 12px;
    margin-right: 7px;
    position: absolute;
    top: 0.3em;
    left: 0;
    width: 12px;
  }

  input[type='radio'].is-error + label:before,
  input[type='checkbox'].is-error + label:before {
    border-color: $color-red;
  }

  input[type='radio'].is-disabled,
  input[type='checkbox'].is-disabled,
  input[type='radio'][disabled],
  input[type='checkbox'][disabled],
  .is-disabled input[type='radio'],
  .is-disabled input[type='checkbox'] {
    & + label {
      cursor: default;
      opacity: 0.5;

      &:hover:before {
        border-color: $color-gray;
      }
    }
    & + label:before {
      background: #fff;
    }
  }

  // if it's a radio make it round
  input[type='radio'] ~ label:before {
    @include border-radius(7px);
  }

  // the checked mechanism
  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 0; // IE

    // the declaration is duplicated because IE won't take them if we don't do this
    // content is changed because IE: http://stackoverflow.com/a/8852418
    &.checked + label:before {
      background: $color-main;
      content: ' ';
    }
    &:checked + label:before {
      background: $color-main;
      content: ' ';
    }
    &:focus + label:before,
    &:hover + label:before {
      border-color: $color-main;
    }
  }
}

.form-field {
  margin-bottom: $spacing-double;

  @include grid-media-query('palm') {
    margin-bottom: $spacing-half;
  }

  label,
  .label {
    @extend %text--small-caps;
    color: $color-darker-gray;
    display: block;
    margin: $spacing 0;
    cursor: pointer;
  }

  .additional {
    color: $color-dark-gray;
  }

  .error-message {
    @include font-size(14px);
    color: $color-red;
    display: none;
    vertical-align: top;
    padding: $spacing-half $spacing;

    &.is-error {
      display: inline-block;
    }

    &:before {
      left: -7px;
      position: relative;
      text-indent: 0;
    }
  }

  input,
  .input {
    display: block;
    margin-right: $spacing;

    &--full {
      width: 100%;
    }

    &.is-error {
      border-color: $color-red !important;
      &:focus {
        background: mix($color-red, #fff, 10%);
      }
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='submit'],
  input[type='tel'],
  input[type='email'],
  input[type='number'] {
    display: inline-block;
  }

  textarea {
    width: 100%;
  }

  .radio-group li {
    display: inline-block;
    margin: 0 $spacing-double 0 0;
    text-transform: none;

    &:last-child {
      margin-right: 0;
    }
  }

  .input--date {
    width: 15%;
    margin-right: $spacing;
  }

  .input--phone {
    width: 40%;
  }

  .input--ext {
    width: 15%;
  }
}

.form-field {
  &--inner-icon {
    position: relative;

    input[type='text'],
    input[type='password'],
    input[type='submit'],
    input[type='tel'],
    input[type='email'],
    input[type='number'] {
      padding-left: 28px;
      padding-right: 30px;
      box-sizing: border-box;
      width: 100%;
    }
  }

  .inner-icon {
    color: $color-main;
    left: 5px;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  .inline-button {
    background-color: $color-light-gray;
    border-left: 1px solid $color-gray;
    border-radius: 0;
    color: $color-main;
    height: 23px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 1px;
    width: 23px;

    span {
      display: block;
      margin-top: -2px;

      &:before {
        font-size: 15px;
      }
    }
    &--secondary {
      background: $color-secondary;
    }
  }
}

.form {
  @at-root {
    &__field-icon {
      color: $color-main;
      left: 5px;
      position: absolute;
      top: 0;
    }

    &__field-button {
      position: relative;
    }

    &--inline &__field-button &__field-icon + input {
      padding-left: 28px;
    }

    &--inline {
      display: inline-block;
    }

    &--inline &__label,
    &--inline &-field {
      display: inline-block;
      vertical-align: middle;
    }

    &--inline &__field-button {
      margin-bottom: 0;
      margin-left: $spacing;

      input {
        color: $color-dark-gray;
        margin-right: 28px;
        padding: 1px $spacing * 2 1px 5px;

        &.is-error:focus {
          background: #fff;
        }
      }

      .error-message {
        background: #fff;
        bottom: -28px;
        font-size: 15px;
        left: 0;
        padding: 2px 5px;
        position: absolute;
      }

      &--large input {
        width: 300px;

        @include grid-media-query(palm) {
          width: 240px;
        }
      }
    }

    &--inline &__field-button &__btn {
      background-color: $color-light-gray;
      border-left: 1px solid $color-gray;
      border-radius: 0;
      color: $color-main;
      height: 23px;
      position: absolute;
      right: 3px;
      text-align: center;
      top: 2px;
      width: 23px;

      span {
        display: block;
        margin-top: -2px;

        &:before {
          font-size: 15px;
        }
      }

      &--secondary {
        background: $color-secondary;
      }
    }
  }
}
