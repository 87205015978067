.media {
    margin: $spacing;

    &, &__content {
        overflow: hidden;
    }

    &__img {
        float: left;
        margin-right: $spacing-double;
        max-width: 50%; //Prevents the picture from squeezing the text too much

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}



.media-overlay {
    > img {
        display: block;
    }
    position: relative;

    .overlay {
        @include font-size(13px);

        background: rgba(0,0,0,.3);
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        padding: $spacing-half;
        position: absolute;
        width: 100%;
        text-align: center;
    }
}
