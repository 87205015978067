.aside-navigation {
    @include font-size(15px, 24px);
	background-color: white;
	padding: 0 spacing;

	&__item {
		border-bottom: 1px solid $color-warm-grey;

		&:first-child {
			border-top: 1px solid $color-warm-grey;
		}

		&.is-active {
			padding-bottom: 7px;

			> a {
				padding-bottom: 4px;
			}
		}

		> a {
			font-weight: 900;
			padding: 11px 0;
		}
	}

	&__sub-item {
		&.is-active > a {
			color: $color-main;
			font-weight: 600;
		}
		> a {
			padding: 4px 0;
		}
	}
}