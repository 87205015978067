.table,
%table {
    width:100%;

    &--default {
        @include font-size(13px, 16px);
        margin-bottom: $spacing-double;

        th {
            text-align: left;
            font-weight: normal;
        }
    }

    &--small {
        @include font-size(15px, 24px);

        tr {
            border-top: 1px solid $color-warm-grey;
            border-bottom: 1px solid $color-warm-grey;
        }

        th {
            text-align: left;
            padding: 7px $spacing 7px 0;
            font-weight: normal;
			vertical-align: top;
            white-space: nowrap;
        }

        td {
            color: $color-warm-black;
            text-align: right;
            font-weight: bold;
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    &--vertical {
        td {
            padding: $spacing;
        }
        .full {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--main {
        @include font-size(14px);

        thead {
            border-bottom: solid 1px $color-secondary;
        }
        th {
            @extend %text--small-caps;
            @extend %text--hard;
            padding-bottom: $spacing-half;
            padding-left: $spacing-double;
            padding-top: $spacing-half;
            text-align: left;
        }
        td {
            border-bottom: dotted 1px $color-secondary;
            padding-bottom: $spacing;
            padding-left: $spacing-double;
            padding-top: $spacing;
            word-wrap: break-word;
        }

    }


    /**
     * Extends .table
     * Table used for listing
     * Responds on palm by showing every <td> onen under another, with a line separating each tr
     */
    &--listing {
        @extend %table--main;

        thead {
            // hide the head on mobile
            @include grid-media-query("palm") {
                display: none;
            }
        }
        th {
            @include grid-media-query("palm") {
                padding-left: $spacing;
            }
        }
        tr {
            @include grid-media-query("palm") {
                border-bottom: dotted 1px $color-secondary;
                box-sizing: border-box;
                display: block;
                margin-bottom: $spacing;
                overflow: hidden;
                padding-bottom: $spacing-half;
                padding-right: $spacing-double;
                position: relative;
            }
        }
        tr td:first-child,
        tr th:first-child {
            @include grid-media-query("portable-up") {
                padding-left: 0;
            }
        }
        td {
            @include grid-media-query("palm") {
                border-bottom: 0;
                display: block;
                padding-bottom: $spacing-half;
                padding-left: $spacing;
                padding-top: 0;
            }
        }
        @include grid-media-query("palm") {
            tbody tr td:first-child {
                font-weight: bold;
            }

            // just so that the icon is displayed centered to the right (usually an arrow)
            // needs to be position absolute, because we are forced display table elements
            // as blocks for the responsive
            .line-icon {
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        tbody tr:hover td {
            color: $color-main;
        }
    }
}

.v-top {
    vertical-align: top;
}
.v-middle {
    vertical-align: middle;
}
.v-bottom {
    vertical-align: bottom;
}


/**
 * In the HTML above we see several `col` elements with classes whose numbers
 * represent a percentage width for that column. We leave one column free of a
 * class so that column can soak up the effects of any accidental breakage in
 * the table.
 */
.t0     { width: 0 }
.t5     { width: 5% }
.t10    { width:10% }
.t12    { width:12.5% }     /* 1/8 */
.t15    { width:15% }
.t20    { width:20% }
.t25    { width:25% }       /* 1/4 */
.t30    { width:30% }
.t33    { width:33.333% }   /* 1/3 */
.t35    { width:35% }
.t37    { width:37.5% }     /* 3/8 */
.t40    { width:40% }
.t45    { width:45% }
.t50    { width:50% }       /* 1/2 */
.t55    { width:55% }
.t60    { width:60% }
.t62    { width:62.5% }     /* 5/8 */
.t65    { width:65% }
.t66    { width:66.666% }   /* 2/3 */
.t70    { width:70% }
.t75    { width:75% }       /* 3/4*/
.t80    { width:80% }
.t85    { width:85% }
.t87    { width:87.5% }     /* 7/8 */
.t90    { width:90% }
.t95    { width:95% }
