.box {
    padding: $spacing-double;
    border: 1px solid $color-light-gray;
    position: relative;

    &--lime {
        background-color: $color-lite-lime-green;
    }

    &--green {
        border: 1px solid $color-main;
    }

    &--rounded {
        border-radius: 4px;
    }

    &--white {
        background-color: #fff;
    }
    
    &--grey {
        background-color: $color-warm-grey;
    }

    &--textured {
        background: url(../images/thriftyfoods-light-background.png) #fff;
    }

    &--transparent {
        background-color: transparent;
    }

    &--no-border {
        border: 0;
    }

    &--dashed {
        border-style: dashed;
    }

    &--textarea-mimic {
        max-height:100px;
        overflow:auto;
    }

	&--warning {
		border-color: $color-red;

		.icon {
			color: $color-red;
		}

		.btn {
			float: right;
			margin-left: $spacing;

			&,
			&:hover {
				color: #FFF;
			}
		}
	}

    &--small {
        padding: $spacing $spacing-large;
    }

    &--iconed {
        padding-left: 50px;
        > .icon {
            position: absolute;
            left: $spacing-large;
        }
    }


    td {
        //padding-bottom:$spacing-and-half*2;
    }

    tr:last-child {
        td {
            padding-bottom:0;
        }
    }

    > a {
        &:hover {
            color:inherit;
        }
    }

    // passe passe avec un after en position absolute pour permettre de toujours
    // occuper 100% de l'espace en hauteur
    &--full {
        &:after {
            content: "";
            height: 1000px;
            position: absolute;
            background-color: $color-tfs-green;
            background-color: rgba($color-tfs-green, .7);
            top: 0; left: 0;
            width: 100%;
            z-index: 1;
        }
        & .box__content {
            position: relative;
            z-index: 2;
        }
    }
}


