.tooltip {
    position:relative;
    display:inline-block;
    padding:0 $spacing;

    .tip {
        width:200px;
        position:absolute;
        z-index:1;
        top:-$spacing-and-half;
        left:150%;

        &:before {
            content: "";
            position:absolute;
            left:-15px;
            top:15px;
            width:0;
            height:0;
            border-style: solid;
            border-width: 15px 15px 15px 0;
            border-color: transparent $color-very-light-gray transparent transparent;
        }

        .box {
            min-height:50px;
        }
    }

    .close {
        position:absolute;
        z-index:2;
        right:-8px;
        top:-8px;
    }

}

