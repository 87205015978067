// =Category
// --------------------------------------------------

.category-description {
  margin-bottom: 3em;
}

ul.listing-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

li.listing-item {
  list-style: none;
  margin: 0;
  padding: 2em 0;

  border-top: 8px solid $color-warm-grey;

  &:first-child {
    border: 0;
    padding-top: 0;
  }

  &:before {
    display: none;
  }
}

.photo-area a {
  display: inline-block;
  width: 100%;
}

.photo-area img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.sushi-guide {
  border-top: 8px solid $color-warm-grey;
  padding-top: 2em;
}

// =Add to Cart
// --------------------------------------------------

.order {
  background-color: #fff;
  padding-bottom: 1em;

  .submit {
    padding: 0 15px;
  }
}

// TODO: make icon a bg image so change to black on hover
.order .submit button {
  width: 84px;
  background-image: url(/images/icons/icon-cart-reverse.svg);
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 11px 5px;

  &:focus,
  &:hover,
  &:active {
    background-image: url(/images/icons/icon-cart-black.svg);
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: 11px 5px;
  }
}

.order .submit button .txt {
  display: inline-block;
  margin-left: 24px;
}

// .order .submit button img {
//   width: 22px;
// }

// .order .submit button > * {
//   display: block;
//   float: left;
// }

.order-grid {
  font-size: 13px;
  width: 100%;
  margin-bottom: 1em;

  caption {
    @include hide();
  }

  th {
    line-height: 1.4;
    vertical-align: bottom;
    text-transform: uppercase;
    font-size: 11px;
  }

  .turkeys th {
    font-size: 10px;
  }

  .txt {
    @include hide();
  }

  tr {
    border-bottom: solid 1px $color-warm-grey;
  }

  tr:first-child {
    border: 0;
  }

  td {
    // each table cell looks like a row
    box-sizing: border-box;
    float: left;
    clear: both;
    width: 100%;
    padding: 0 15px;
  }

  td.size {
    padding-top: 1em;
    padding-bottom: 0.5em;
  }

  td.price {
    color: $color-tf-dark-green;
  }

  td.amount {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .amount label {
    @include hide();
  }

  .number {
    box-sizing: border-box;
    display: inline-block;
    font-weight: bold;
    border: 1px solid $color-tf-dark-green;
    // border-radius: 5px;
    border-radius: 0;
    text-align: center;
    width: 36px;
    height: 36px;
    padding-top: 10px;
    line-height: 1;
  }

  .ui-button {
    box-sizing: border-box;
    display: inline-block;
    // border-radius: 5px;
    border-radius: 0;
    text-align: center;
    width: 36px;
    height: 36px;
    padding-top: 10px;
    line-height: 1;
    margin-right: 7px;
  }

  strong.size {
    font-weight: 900;
  }
}

// =Sidebar Cart
// --------------------------------------------------

.shopping-cart {
  @include grid-media-query('portable') {
    margin-bottom: 2em;
  }

  @include grid-media-query('palm') {
    margin-bottom: 2em;
  }
}

.shopping-cart .card__header > * {
  float: left;
}

.shopping-cart .card__header .cart__icon {
  height: 24px;
  display: inline-block;
  margin-right: 4px;
}

.shopping-cart .card__body {
  padding-left: 0;
  padding-right: 0;
  clear: both;
}

.shopping-cart-items {
  font-size: 13px;
  width: 100%;

  .columns {
    @include hide();
  }

  td {
    border-top: solid 1px $color-warm-grey;
    padding: 10px 7px;
    text-align: left;
    line-height: 1.4;
    vertical-align: top;
  }

  .cart-summary__quantity .numeric {
    box-sizing: border-box;
    display: inline-block;
    font-weight: bold;
    border: 1px solid $color-tf-dark-green;
    // border-radius: 5px;
    border-radius: 0;
    text-align: center;
    width: 36px;
    height: 36px;
    padding-top: 10px;
    line-height: 1;
  }

  .cart-summary__label {
    color: $color-tf-dark-green;
  }

  .item-price {
    font-weight: bold;
    white-space: nowrap;
  }
}

.view-cart-link {
  border-top: solid 1px $color-warm-grey;
  padding: 1em;

  .btn {
    font-size: 13px;
    max-width: 250px;
  }
}
