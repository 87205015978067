.mini-cart {
  background-color: #fff;
  display: inline-block;
  text-align: center;
  height: 40px;
  // border-radius: 5px;

  @include grid-media-query('palm') {
    background: none;
  }

  > a {
    display: inline-block;
  }

  &__label {
    color: $color-tf-dark-green;
    font-weight: bold;
    border-right: 1px solid $color-warm-grey;
    padding-right: 15px;
  }

  &__amount {
    padding: 0 15px;
    @include grid-media-query('palm') {
      color: white;
      padding-left: 0;
    }

    a:hover &,
    a:focus & {
      text-decoration: underline;
    }
  }

  &__icon {
    color: $color-tf-light-green;
    padding: 0 10px;
  }

  &__label,
  &__amount,
  &__icon {
    float: left;
    line-height: 40px !important;
  }

  .js-cart-subtotal {
    display: none;
  }
}
