.price {
    @extend %font-brand-bold;
    @include font-size(22px, 24px);
    white-space: nowrap;

    &--muted {
        color: $color-gray;
    }

    &--featured {
        color: $color-red;
    }
}