.mosaic {
    @extend %clearfix;

    .mosaic__item {
        float: left;
        width: 25%;

        &:first-child {
            width: 50%;
        }
        img {
            display: block;
            width: 100%;
        }

        @include grid-media-query('palm') {
            width: 50%;

            &:first-child {
                width: 100%;
            }
        }
    }
}

