/**
 * Free text styles
 */
.text {
  @include font-size(16px);
  color: $color-text;

  a {
    color: $color-main;
  }
  p {
    @include font-size(16px);
    color: $color-text;
  }

  ol {
    counter-reset: counter;
    list-style: none;
    margin-bottom: $spacing-double;
    ol {
      margin-left: $spacing-double;
      margin-top: $spacing-half;
      margin-bottom: 0;
    }
    li {
      padding-left: 36px + $spacing-large;
      padding-top: 5px + $spacing-double;
      padding-bottom: 5px + $spacing-double;
      position: relative;

      &:before {
        @include font-size(18px, 36px);
        color: #fff;
        content: counter(counter);
        counter-increment: counter;
        position: absolute;
        width: 36px;
        left: 0;
        top: $spacing-double;
        background-color: $color-main;
        text-align: center;
        border-radius: 50%;
        font-weight: 700;
      }

      + li {
        border-top: 1px solid $color-warm-grey;
      }
    }
  }

  ul {
    counter-reset: counter;
    list-style: none;
    margin-bottom: $spacing-double;
    padding-left: $spacing-double;

    ul {
      margin-left: $spacing-double;
      margin-top: $spacing-half;
      margin-bottom: 0;
    }
    li:first-child {
      margin-top: 0;
    }
    li {
      margin-top: $spacing-half;
      position: relative;
    }
    li:before {
      content: '\2022';
      font-size: 25px;
      position: absolute;
      left: -$spacing-double;
    }
  }

  &--line-behind {
    position: relative;
    overflow: hidden;

    span {
      display: inline-block;
      vertical-align: baseline;
      zoom: 1;
      *display: inline;
      *vertical-align: auto;
      position: relative;
      padding: 0 $spacing-half;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 1000px;
        position: absolute;
        top: 0.8em;
        border-top: 1px solid $color-warm-grey;
      }

      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
  }
}

.text--normal {
  @extend %font-default;
  color: $color-text;
}

.text--smaller {
  @include font-size(13px, 16px);
}

.text--small {
  @include font-size(15px, 24px);
}

.text--large {
  @include font-size(18px, 21px);
}

.text--larger {
  @include font-size(26px, 32px);
}

.text--huge {
  @include font-size(48px, 48px);
}

.text--close-fitting {
  line-height: 1.1em;
}

.text--list-anonym {
  li:before {
    display: none !important;
  }
  li {
    padding: 0 !important;
    border: none !important;
  }
}

/**
* Colors
*/
.text--brand,
.text--positive,
%text--brand {
  color: $color-main !important;
}
.text--secondary {
  color: $color-secondary !important;
}
.text--muted {
  color: $color-muted !important;
}
.text--discret {
  color: $color-medium-gray !important;
}
.text--contrast {
  color: #fff !important;
}
.text--hard,
%text--hard {
  color: $color-warm-black !important;
}
.text--important,
%text--important {
  color: $color-red !important;
}
.text--caps {
  text-transform: uppercase !important;
}

.text--small-caps,
%text--small-caps {
  @include font-size(11px);
  color: $color-dark-gray;
  font-weight: bold;
  font-family: arial, sans-serif;
  text-transform: uppercase;
}

.text--strong {
  font-weight: bold;
}

/**
 * order status color code
 */
.text--status-cancelled {
  @extend %text--important;
}
.text--status-pending,
.text--status-delivered,
.text--status-shipped {
  @extend %text--brand;
}

/**
* Special texts
*/
.text--footnote {
  @include font-size(11px, 16px);
}
.text--striked {
  text-decoration: line-through;
}

/**
 *  text-identation and alignement with icon
 */
.text--icon--indent {
  position: relative;
  padding-left: $spacing-double * 2;

  .icon {
    position: absolute;
    left: 0;
  }
}

/**
 * Default tags
 */
p {
  margin: 0 0 $spacing 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    color: $color-links-hover;
    text-decoration: underline;
  }

  &.no-href {
    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}

hr {
  border: 0;
  border-top: 1px dotted $color-main;
  margin: $spacing-double 0 $spacing 0;
  overflow: visible; // ie

  &.alt:after {
    border: 0;
    border-top: 1px dotted $color-main;
    content: ' ';
    height: 1px;
    display: block;
    margin-top: 2px;
  }

  &.solid {
    border-top-style: solid;
  }

  &.discreet,
  &.variant {
    &,
    &.alt:after {
      border-color: $color-light-gray;
    }
  }

  &.light {
    &,
    &.alt:after {
      border-color: #fff;
    }
  }

  &.large {
    margin: $spacing-double 0;
  }

  &.small {
    margin: $spacing 0;
  }

  &.seam {
    border-top: 1px solid rgba($color-lime-green, 0.4);
    border-bottom: 1px solid rgba(#fff, 0.6);
  }

  &.strong {
    border-top: 8px solid $color-lime-green;
  }
}

/**
 * Headings
 */
h1 {
  @extend %font-alt;
  font-weight: normal;
  // font-weight: bold;
}

h2,
h3,
h4,
h5,
h6 {
  // @extend %font-brand-bolder;
  @extend %font-alt;
}

h1,
h2 {
  color: $color-main;
  text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
  color: $color-warm-black;
}

/*h1, h2 { color: $color-leaf-green; }
h3, h4, h5, h6 { color: $color-black; }*/
h1 {
  // @include font-size(48px, 48px);
  @include font-size(36px, 36px);
  padding-bottom: $spacing;
}
h2 {
  @include font-size(26px, 32px);
}
h3 {
  @include font-size(21px, 24px);
}
h4 {
  @include font-size(18px, 21px);
}
h5 {
  @include font-size(16px, 18px);
}
h6 {
  @include font-size(12px);
}

.h1-like {
  @extend h1;
}
.h2-like {
  @extend h2;
}
.h3-like {
  @extend h3;
}
.h4-like {
  @extend h4;
}
.h5-like {
  @extend h5;
}
.h6-like {
  @extend h6;
}
