// =Help (was FAQs)
// --------------------------------------------------

.help h2 {
  margin-bottom: 1em;
}

.help hr.strong {
  margin-top: 2em;
  margin-bottom: 2em;
}

.help h3.h4-like {
  margin-top: 1em;
}
