.newsletter-form {
	@include grid-media-query("portable-up") {
		display: table;
		width: 100%;
	}

	&__label,
	&__input {
		.input-group__btn {
			width: 40px;
		}
	}

	&__label {
		font-weight: 900;
		line-height: 40px;
		@include grid-media-query("palm") {
			text-align: center;
		}
	}

	.form-field {
		margin: 0;
	}

	.icon:before {
		width: 20px;
	}

	&--block {
		background-color: $color-main;
		box-sizing: border-box;
		margin-bottom: 30px;
		padding: 50px 30px;
		@include grid-media-query("palm") {
			display: none;
		}
		h3 {
			color: $color-secondary;
		}

		p {
			color: white;
		}
	}

	&--inline {
		.newsletter-form__label,
		.newsletter-form__input {
			display: block;
			@include grid-media-query("portable-up") {
				vertical-align: middle;
				display: table-cell;
			}
		}

		.newsletter-form__input {
			@include grid-media-query("portable-up") {
				&__input {
					width: 75%;
				}
			}
		}
	}
}