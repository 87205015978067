.checkout__iframe {
	height: 750px;
	width: 100%;
}

.checkout-footer {

    padding-top:20px;

    .checkout-footer__content {
        width: 100%;

        @include grid-media-query("portable") {
            position: relative;
        }
        @include grid-media-query("palm") {
            position: relative;
        }
    }
}

.checkout-ends {
    background: $color-main;
    position: relative;
    z-index: 2;
}
