.item-block {
    background: #fff;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;

    .carousel & {
        width: 291px;

        @include grid-media-query(palm) {
            width:100%;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    &--recipe {
        .add-to-recipe-box {
            float: right;
        }
        tr {
            border-bottom: 1px solid $color-warm-grey;
            border-top: 1px solid $color-warm-grey;
        }

        .box--white & {
            border: 1px solid $color-warm-grey;
        }
    }
    
    &--article &__media {
        padding: 0;
    }

    &__content {
        padding: $spacing-x-large;
        @include grid-media-query(palm) {
            padding: $spacing;
        }
    }

    &__media {
        @include grid-media-query(palm) {
            padding: $spacing 0 $spacing $spacing;
        }
        img {
            display: block;
            height: auto;
            width: 100% !important;
        }
    }

    &__section-title {
        @include font-size(13px, 16px);
        color: $color-main;
        text-transform: uppercase;
    }

    &__title {
        margin: $spacing-double 0;
        &:first-child {
            margin-top: 0;
        }
    }

    &__read-more {
        @include font-size(15px, 24px);
        color: $color-main;
        font-weight: bolder;
    }

    &__summary {
        @include font-size(15px, 24px);
    }

    &__title,
    &__summary {
        margin: $spacing-double 0;
    }
    
    &__section-title,
    &__title,
    &__read-more,
    &__summary {
        @include grid-media-query(palm) {
            @include font-size (13px, 16px);
        }
    }
}
