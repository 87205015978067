.banner {
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: $spacing-double;

    &__text {
        display: table-cell;
        padding: 50px 40px;
        background: url(../images/thriftyfoods-light-background.png) #fff;
        vertical-align: top;

        @include grid-media-query('portable') { 
            width: percentage(2/3);
        }
        @include grid-media-query('desk') { 
            width: percentage(2/4);
        }
    }
    
    &__newsletter {
        display: none;

        @include grid-media-query('portable-up') { 
            display: table-cell;
            width: percentage(1/3);
            background-color: $color-main;
            vertical-align: top;
        }

        @include grid-media-query('desk') { 
            width: percentage(1/4);
        }
    }

    &__media {
        display: none;

        @include grid-media-query('desk') { 
            display: table-cell;
            width: percentage(1/4);
            overflow: hidden;
            position: relative;
        }

        img {
            position: absolute;
        }
    }
}
