
.card {
    background: url(../images/thriftyfoods-light-background.png) #fff;

    .box--white & {
        border: 1px solid $color-warm-grey;
    }

    &__header {
        background-color: $color-main;
        color: #fff;
        padding: $spacing $spacing-large;
        a:hover,
        a:focus, {
            color: inherit;
        }
    }

    &__body {
        padding: $spacing-large $spacing-double;

        .full-bleed {
            margin-left: -$spacing-double !important;
            margin-right: -$spacing-double !important;
        }
    }

    &__header + &__body {
        padding-top: $spacing;
    }

    &.is-active {
        border:1px solid #000;
    }

    .media {
        margin: 0;
        padding: $spacing $spacing-double;
        img {
            max-width:150px;
        }
    }

    &__feedback {
        display:none;
   
        &.show-feedback {
            display:inline;
            animation: showFeedback 5s forwards;
        }


    }
}

/* Standard syntax */
@keyframes showFeedback {
    0%   { opacity: 0; }
    5%   { opacity: 1; }
    95%  { opacity: 1; }
    100% { opacity: 0; }
}