.pill {
    @extend %font-brand-bold;
    @include border-radius(50%);
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px; //Vertical center
    text-align: center;

    &:before {
        font-size: 28px;
        vertical-align: -6px; // fix for misaligned font
    }

    &--small {
        width:40px;
        height:40px;
        line-height:40px;
    }

    &--default {
        background: $color-secondary;
        color: $color-variant;
    }
}
