.roll-up {
	background-color: white;

	&__content {
		padding: $spacing-x-large;
		.box--white & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__text,
	&__read-more {
		@include font-size(15px, 24px);
	}

	&__read-more {
		color: $color-main;
		font-weight: 900;

		&:hover,
	    &:focus {
	    	text-decoration: underline;
	    }	
	}
}