.slideshow {
    text-align: center;
	&__title {
		color: #fff;
        margin: 0;
        @include font-size(96px, 96px);
	}

	&__description {
        @include font-size(24px, 24px);
        font-weight: lighter;
        margin: $spacing 0 $spacing-double 0;
	}

    &__item {
        position: relative;
        display: none;
        color: #fff;
        
        &:first-child {
            display: block;
        }

        .owl-item & {
            display: block;
            padding-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }

        > img {
            display: block;
            width: 100%; height: auto;
        }
    }

    .owl-wrapper-outer{
        padding-left: 0;
        margin-left: 0;
    }

    &__content-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        white-space: nowrap;

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em;

        }
    }

    &__content {
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }

    .owl-controls {
        position: absolute;
        bottom: 5px;
        width: 100%;
    }
    
    .owl-page {
        @include border-radius(50%);
        background: white;
        display: inline-block;
        height: 12px;
        margin: 0 7px;
        transition: background .15s ease-out;
        width: 12px;

        &:hover,
        &:focus {
            background: darken($color-main, 10px);
        }

        &.active {
            background: $color-main;
        }
    }
}