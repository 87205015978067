.island,
%island {
    background: $color-very-light-gray;
    box-sizing: border-box;

	&--warning {
		border: solid 1px $color-tfs-green;
	}

	&--warning &__container .icon {
		color: $color-tfs-green;
		top: 3px;
	}

}