.add-to-cart {
    position: relative;
    display: block;

    .feedback {
        background: $color-main;
        bottom: 100%;
        box-sizing: border-box;
        color: #fff;
        display: none;
        font-size: 11px;
        font-weight: bold;
        left: 0;
        margin-bottom: 10px;
        padding: 4px 5px;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 1;
    }
    &.is-added .feedback {
        display: block;
        animation: addToCartFeedback 5s forwards;
    }


    &.is-loading &__add {
        color: $color-main !important;
    }

    .btn {
        @include font-size(15px);
        height: 34px;
        padding: 3px 7px;
        vertical-align: middle;
    }
}

/* Standard syntax */
@keyframes addToCartFeedback {
    0%   { opacity: 0; }
    5%   { opacity: 1; }
    95%  { opacity: 1; }
    100% { opacity: 0; }
}

.controls {
    display: table;
    position: relative;
    vertical-align: middle;
    width: 100%;

    .control {
        display: table-cell;
        width: 40px;

        &--fluid {
            width: auto;
        }
        &--zero {
            width: 0;
        }
    }
}
