.social-share {
    color: $color-secondary;
    font-weight: 900;
    display: inline-block;
    @include grid-media-query("palm") {
        display: block;
        text-align: center;
        margin-bottom: $spacing-half;
    }

    &__label {
        line-height: 40px;
        vertical-align: middle;
    }

    &__link {
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        height: 36px;
        margin-left: 3px;
        text-align: center;
        vertical-align: middle;
        width: 36px;

        &:before {
            color: $color-main;
            line-height: 36px;
        }
    }
}