.secondary-nav {
	color: $color-secondary;

	@include grid-media-query("portable-up") {	
		background: $color-main;
	}

	a {
		color: $color-warm-black;

		@include grid-media-query("portable-up") {	
			
			color: #fff;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.nav,
    &__customer-service,
    &__login {
        @include font-size(13px, 45px);
        height: 45px;
    }

	&__customer-service {
		display: none;
		margin-left: $spacing;

		@include grid-media-query("desk") {
	        display: inline;
	    }

		span {
			color: #fff;
		}
	}

	.nav {
		text-align: center;
		@include grid-media-query("portable-up") {
			text-transform: uppercase;
		}

		li + li {
			margin-left: $spacing-large;
		}

		a:hover .nav__label,
	    a:focus .nav__label {
	        display: block;
	        color: $color-main;
	    }
	}

	&__login {
		text-align: right;
	}
}