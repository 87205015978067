.map {
    position: relative;

	&__overlay {
		background-color: $color-very-light-gray;
		background-color: rgba(250, 250, 250, 0.9);
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;

		&__container {
			padding: 30px 90px;
		}

		&__image {
			float: left;
		}

		&__content {
			margin-left: 140px;
		}

		&__image {
			margin: 0 40px $spacing 0;
			width: 100px;
		}

		img {
			max-width: 100%;
		}

        @include grid-media-query("palm") {
            position: relative;

            &__container {
                padding: 20px;
            }
        }
    }

    &__gmap {
        height: 500px;
        width: 100%;

        @include grid-media-query("palm") {
            height: 240px;
        }
	} 
}



.contextual-map {
    height: 200px;
}
