/*------------------------------------*\
    $NAV
\*------------------------------------*/
/**
 * Nav abstraction as per: csswizardry.com/2011/09/the-nav-abstraction
 * When used on an `ol` or `ul`, this class throws the list into horizontal mode
 * e.g.:
 *
   <ul class=nav>
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 *
 * Demo: jsfiddle.net/inuitcss/Vnph4
 *
 */
.nav{
    list-style:none;
    margin-left:0;
    @extend %clearfix;

    > li{
        &,
        > a{
            display: inline-block;
           *display: inline;
            zoom: 1;
        }
    }
}

.nav--top {
    > li {
        vertical-align: top;
    }
}

.nav--stacked{
    > li{
        display:list-item;
        > a{
            display:block;
        }
    }
}

.nav--collapsible {
    box-sizing: border-box;

    li,
    a {
        display: block;
    }

    .child,
    .btn--show-more,
    .icon--arrow-up {
        display: none;
    }

    .icon--arrow-up,
    .icon--arrow-down {
        margin-top: -4px;
    }

    .is-active {
        .child,
        .icon--arrow-up {
            display: block;
        }
        .btn--show-more {
            display:inline-block;
        }
        .icon--arrow-down {
            display: none;
        }
    }

    &__info {
        float: right;
    }
}

.nav-bits {
    text-align: center;

    li {
        @include font-size(15px, 28px);
        background: $color-warm-black;
        border-radius: 50%;
        margin-left: 2px;
        margin-right: 2px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        width: 28px; 
        height: 28px;
        font-weight: bold;
    }
    li.is-active {
        background: $color-main;
    }
}