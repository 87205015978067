.toggler {
    //padding-bottom: 0 !important;
    overflow: hidden;
    padding-top: 10px;
    border-top: solid 1px $color-gray;

    &--full-border {
        border:solid 1px $color-gray;
    }

    .card__toggler:first-child & {
        border-top:none;
        border-top:0;
    }

    & &__btn {
        padding-right: 25px;
        position:relative;
        color: $color-main;

        &:before {
            @extend %icons;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        @extend %icon--arrow-down;
        cursor: pointer;



        &:hover,
        &:focus {
            color: $color-dark-gray;
        }
    }
    & &__content {
        display: none;
    }

    &.is-active {
        padding-bottom: $spacing-double !important;
    }
    &.is-active &__content {
        display: block;
    }
    &.is-active &__btn {
        @extend %icon--arrow-up;
    }
}
 


.collapsible {

    &__content {
        display:none;
    }

    &.is-active &__content{
        display:block;
    }

    &__btn--close {
        display:none; 
    }
    
    &.is-active &__btn--close { 
        display:inline-block;
    }
    &.is-active &__btn--open { 
        display:none;
    }

}