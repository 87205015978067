.figure {
    &__media {
        display: block;
        width: 100%;
    }

    &__caption {
        color: $color-warm-black;
        background: #fff;
        padding: $spacing-double $spacing-x-large;
        font-weight: 900;
    }
}
