.adChoices {
  top: 46%;
  z-index: 999999;
  position: fixed;
  display: block;
  border-radius: 3px;

  right: -48px;
  background-color: white;

  border: 2px solid $color-tf-dark-green;
  width: 109px;
  padding: 0px 0px 29px;
  transform: rotate(270deg);
  // right: 20px;

  a {
    text-indent: -9999999px;
    display: block;
    cursor: pointer;
    position: absolute;
    background-position: 12px 5px;
    background-image: url(../images/adchoice_icon.png);
    background-repeat: no-repeat;
    right: 8px;
    height: 30px;
    width: 100px;
  }

  button {
    // text-indent: -9999999px;
    // display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    border: 0;
    color: white;
    background-color: $color-tf-dark-green;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    right: -12px;
    cursor: pointer;
    bottom: 18px;
    line-height: 1;

    img {
      display: block;
      width: 12px;
      height: 12px;
    }

    // &:after {
    //   content: '✕';
    //   font-weight: bold;
    //   display: block;
    //   width: 24px;
    //   // height: 24px;
    //   position: absolute;
    //   top: 4px;
    //   left: 0;
    //   color: #fff;
    //   text-indent: 0;
    //   text-align: center;
    //   line-height: 1;
    //   font-size: 24px;

    //   // background-color: red;
    // }
  }
}
