.tabs {
	li{
		a {
		    @extend %font-brand-bolder;
		    @include font-size(15px, 24px);
		    border-radius: 5px;
		    border: 1px solid $color-main;
		    box-sizing:border-box;
		    cursor: pointer;
		    display: block; 
		    min-width: 143px;
		    padding: $spacing-half $spacing-double;

	        &:hover,
	        &:focus {
	            background-color: $color-secondary;
	            color: inherit;
	            text-decoration: none;
	        }
		}

		&[data-is-current-tab='True'] {
			a {
				background-color: $color-main;
				color: #fff;

				&:focus,
				&:hover {
					background-color: darken($color-main, 10%);
					border-color: darken($color-main, 10%);
					color: darken(#fff, 10%);
				}
			}
		}
	}
}