.breadcrumb {
    margin: 17px 0;
    padding-left: spacing-double;
    > li {
        @include font-size(15px, 21px);
        white-space: nowrap;

        + li:before {
            content:"> ";
            color: $color-main;
        }

        > a {
            color: $color-grey;
            text-decoration: underline;

            &:focus,
            &:hover {
                color: $color-main;
            }
        }
    }
}