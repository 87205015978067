@each $name in $breakpoint-has-widths {
    .#{$name}--hide {
        @include grid-media-query($name) {
            display: none !important;
        }
    }

    @include grid-media-query($name) {
        .#{$name}--flush--left { margin-left: 0 !important; }

        .#{$name}--soft { padding: $spacing !important; }
        .#{$name}--soft--top { padding-top: $spacing !important; }
        .#{$name}--soft--right { padding-right: $spacing !important; }
        .#{$name}--soft--bottom { padding-bottom: $spacing !important; }
        .#{$name}--soft--left { padding-left: $spacing !important; }
        .#{$name}--soft--ends { padding-top: $spacing !important; padding-bottom: $spacing !important; }
        .#{$name}--soft--sides { padding-right: $spacing !important; padding-left: $spacing !important; }

        .#{$name}--soft-medium { padding: $spacing-double !important; }
    }

    .#{$name}--text-left {
        @include grid-media-query($name) {
            text-align: left !important;
        }
    }
    .#{$name}--text-right {
        @include grid-media-query($name) {
            text-align: right !important;
        }
    }
    .#{$name}--text-center {
        @include grid-media-query($name) {
            text-align: center !important;
        }
    }
    
}

