// =Confirmation
// --------------------------------------------------

.confirmation {
}

.confirmation a.btn--print {
  display: inline-block;
  margin-bottom: 1em;
}

.confirmation h2 {
  margin-bottom: 0.5em;
}

.confirmation hr.strong {
  margin-top: 2em;
  margin-bottom: 2em;
}

