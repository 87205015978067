.refiner {
	background: url(../images/thriftyfoods-light-background.png) #fff;
	padding: $spacing-double $spacing-large;

	> ul > li + li {
		border-top: 1px solid $color-warm-grey;
		margin-top: $spacing-large;
		padding-top: $spacing-large;
	}

	.nav--collapsible__clickable {
    	@include font-size(18px, 22px);
    	color: $color-main;
    	font-weight: 900;
    	padding: 4px 0;
	}

	.child a,
	.btn--show-more,
	.checkbox label {
    	@include font-size(13px, 16px);
    	font-weight: normal;
    	padding: 7px 0;
	}
	.btn--show-more {
		color: $color-main;	
	}

	.checkbox input[type="checkbox"] + label {
		padding-left: 20px;
		&:before {
			top: 7px !important;
		}
	}
}