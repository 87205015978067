.input-group {
	border-collapse: separate;
	display: table;
	height: 40px;
	position: relative;
	width: 100%;

	&--no-border {
		.input-group__text-input,
		.input-group__btn .btn {
			border-color: transparent;
		}
	}

	&__text-input,
	&__btn {
		box-sizing: border-box;
		display: table-cell;
	}

	&__text-input {
		display: inline;
		height: 40px;
		width: 100%;
		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: none;
		}
		.dropdown + & {
			border-left: none;
			border-top-left-radius: 0;
		}
	}

	&__btn {
		width: 40px;
		border-left: none;
		white-space: nowrap;
		.btn {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-left:none;
			border-color: $color-warm-grey;
			height: 40px;
			text-align: center;
			width: 100%;
			line-height: inherit;
			&--hollow {
				border: 1px solid $color-main;
			}
		}
		.is-error + & {
			.btn {
				border-color: $color-red;
			}
		}
	}

	&__inner-wrapper {
		display: table-row;
	}

	&__error-message {
		background-color: white;
		position: absolute;
		top: 45px;
	}

	.dropdown {
		display: table-cell;
		width: 1px;
		white-space: no-wrap;
	}
}