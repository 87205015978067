%font-brand,
.font-brand {
  font-family: 'proxima-nova', Arial, sans-serif;
}
%font-brand-bold,
.font-brand-bold {
  font-family: 'proxima-nova', Arial, sans-serif;
  font-weight: 700;
}
%font-brand-bolder,
.font-brand-bolder {
  font-family: 'proxima-nova', Arial, sans-serif;
  font-weight: 900;
}
%font-alt,
.font-alt {
  // font-family: "sobeys_merchant_scriptregular", sans-serif;
  font-family: 'Londrina Solid', 'proxima-nova', Arial, sans-serif;
}

%font-default,
.font-default {
  font-family: 'proxima-nova', Arial, sans-serif;
}
body {
  @extend %font-default;
}
