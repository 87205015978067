.nutritional-facts {
    @include font-size(12px);
    background-color: white;
    border: 1px solid #000;
    color: #000;
    display: block !important;
    padding: 4px;
    //position:absolute;
    //right:0;
    //top:-199px;
    width: 225px;
    z-index: 900;
    * {
        color: #000;
        @extend %font-default;
        line-height: 1;
    }
    h1 {
        @include font-size(19px, 19px);
        font-weight: bold;
        margin-bottom: 0;
        margin-bottom: 3px;
        margin-top: 0;
    }
    span.Portion {
        display: block;
        margin-bottom: 2px;
    }
    ul {
        border-top: 3px solid black;
        &.FirstLine {
            border-top: 4px solid black;
            margin-top: 4px;
        }
        &.VitaminesBox {
            li {
                padding: 0px 0px 2px 0px;
            }
        }
        li {
            padding: 2px 0px;
            margin-bottom: 2px;
            margin-top: 2px;
            &.TitleNutr {
                font-weight: bold;
                color: #000;
            }
            &.Half {
                width: 48%;
                float: left;
            }
            & + li {
                border-top: 1px solid black;
            }
            span {
                float: right;
            }
            ul {
                margin-top: 3px;
                &.Lipides {
                    border-top: 3px solid #000;
                    margin-top: 4px;
                    li {
                        margin-left: 8px;
                        border: none;
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                        span {
                            margin-top: -7px;
                        }
                    }
                }
                &.Glucides {
                    padding-left: 8px;
                    border-top: 1px solid #000;
                    li {
                        margin-left: 3px;
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                        &:first-child {
                            padding-bottom: 2px;
                        }
                    }
                }
                &.Vitamines {
                    @extend %clearfix;
                    border-top: none !important;
                    margin-left: 0px;
                    margin-bottom: 0px;
                    li {
                        border: none;
                        border-bottom: 1px solid #000;
                        width: 110px !important;
                        overflow: hidden;
                        margin-left: 5px;
                        padding: 0px 0px 3px 0px;
                        margin-top: 0px;
                        &:first-child {
                            margin-left: 0px;
                        }
                    }
                }
                &.VitTwo {
                    li {
                        border-bottom: 0px;
                    }
                }
            }
        }
    }
}
