$base-font-size: 18px;
$base-line-height: 30px;
//$base-text-color: #222;

$spacing: 10px;
$spacing-half: $spacing / 2;
$spacing-and-half: $spacing + $spacing-half;
$spacing-double: $spacing * 2;
$spacing-double-and-half: $spacing-double + $spacing-half;
$spacing-triple: $spacing * 3;

$spacing-large: $spacing-and-half;
$spacing-x-large: $spacing-double-and-half;

$font-alt: 'Londrina Solid', 'proxima-nova', Arial, sans-serif;

/**
 * New colors
 */
// some other green? used on the title image backgrounds #00A13D
$color-tf-dark-green: #12823d;
$color-tf-medium-green: #12823d;
$color-tf-light-green: #9aca3c;
$color-warm-grey: #deddd9;
$color-grey: #5c5e6c;
$color-warm-black: #202225;
$color-red: #b81900;
$color-berry: #df1187;
$color-orange: #ff5200;
$color-maize: #fab800;

$color-text: $color-grey;
$color-smaller-text: $color-warm-black;

/**
 * Old Colors
 */
$color-dark-green: $color-tf-dark-green;
$color-tfs-green: $color-tf-dark-green;
$color-leaf-green: $color-tf-light-green;
$color-lime-green: $color-tf-light-green;
$color-lite-lime-green: $color-tf-light-green;
$color-black: $color-warm-black;
$color-darker-gray: $color-grey;
$color-dark-gray: $color-grey;
$color-gray: $color-grey;
$color-warm-gray: $color-warm-grey;
$color-medium-gray: $color-warm-grey;
$color-light-gray: $color-warm-grey;
$color-very-light-gray: $color-warm-grey;
$color-ajax-loading-layer: rgba(255, 255, 255, 0.5);
$color-ajax-loading-content: $color-very-light-gray;

$color-main: $color-tfs-green;
$color-variant: $color-leaf-green;
$color-secondary: $color-lime-green;
$color-muted: $color-gray;

$color-links: $color-leaf-green;
$color-links-hover: $color-tfs-green;

/**
 * Grid config
 */
$gutter: 20px;
$portable: 768px;
$desk: 1280px;

$breakpoints: (
  'palm' '(max-width: #{$portable - 1})',
  // palm is mobile up to portable
  'portable-up' '(min-width: #{$portable})',
  // portable and up
  'portable' '(min-width: #{$portable}) and (max-width: #{$desk - 1})',
  // portable only
  'desk' '(min-width: #{$desk})' // desktop
);

$push: true;
$pull: true;

$breakpoint-has-widths: ('palm', 'portable', 'portable-up', 'desk');
$breakpoint-has-push: ('portable', 'portable-up', 'desk');
$breakpoint-has-pull: ('portable', 'portable-up', 'desk');

// =Categories
// --------------------------------------------------

$categories: (
  'bakery',
  'breakfast',
  'cheese-meat',
  'deli',
  'fresh-turkeys',
  'fruits-vegetables',
  'gift-baskets',
  'party-favourites',
  'salads',
  'sandwiches',
  'seafood',
  'sushi',
  'sweets-desserts'
);
