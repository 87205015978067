.featured-recipes {
    &__cta {
        margin-top: $spacing-double;
        text-align: center;
    }

    .owl-theme .owl-controls .owl-page.active span {
        background: $color-gray;
        color: #000;
    }

    .owl-theme .owl-controls.clickable .owl-page:hover span,
    .owl-theme .owl-controls.clickable .owl-page:focus span {
        background: #fff;
        color: #000;
    }

}