// =Hide / Show Content
// --------------------------------------------------

// remove completely, even from screen readers
@mixin remove() {
  display: none;
  visibility: hidden;
}

@mixin re-add() {
  display: block;
  visibility: visible;
}

// available for screen-readers
@mixin hide() {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

// undo hide
@mixin show() {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}


// hide text visually

@mixin hide-text($indent: -999em) {
  text-indent: $indent;
}


@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}
