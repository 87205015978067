.popup {
	display: block;
	height: 100%;
	left: 0;
	overflow:hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;

    body > & {
        position: fixed;
    }

	.popup__mask {
		background: rgba(255, 255, 255, 0.5);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		&--dark {
			background: rgba(0, 0, 0, 0.5);
		}
		&--light {
			background: rgba(255, 255, 255, 0.5);
		}
	}

	.popup__content {
		background: #fff;
		border: 1px solid $color-dark-gray;
		margin-top: $spacing;
		margin: 0 auto;
		padding: $spacing $spacing-double;
		position: relative;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 70%;
		z-index: 2;
        max-width: 400px;
        min-width: 300px;
        box-sizing: border-box;
	}
}
