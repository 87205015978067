.search-by-features {
    background: url(../images/thriftyfoods-light-background.png) #fff;
	border: 1px solid $color-warm-grey;
	padding: $spacing-double;
	margin-bottom: $spacing-triple;

	&__list {
        border-top: 1px solid $color-warm-grey;
	}

	&__controls {
		text-align: center;
		margin-top: $spacing-double;
	}

    &__list-item {
        border-bottom: 1px solid $color-warm-grey;
    }

    a {
        display: table;
        width: 100%;
        padding: 4px 15px;
        color: $color-warm-black;

        &:hover,
        &:focus {
        	text-decoration: underline;
        	color: $color-main;
        }
    }

    &__label {
        display: table-cell;
        padding-bottom: 3px;
        padding-right: $spacing;
        padding-top: 3px;
        vertical-align: middle;
        width: 100%;
        font-weight: 900;
    }
    
    &__icon {
        float: left;
    }
}
