.ajax {
    min-height: 35px;
    position: relative;

    &__loading {
        background: $color-light-gray;
        background: $color-ajax-loading-layer;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 100;

        &__content {
            background: $color-light-gray;
            background: $color-ajax-loading-content;
            padding: $spacing 0;
        }

        .ajax__confirmation {
            background:#fff;
            border:1px solid $color-dark-gray;
            padding:$spacing $spacing-double;
            text-align:center;
            position:relative;
            width:70%;
            margin:0 auto;
            margin-top:$spacing;
        }
    }
}


.is-loading {
    // position: relative;

    &:before {
        content: "";
        min-height: 20px;
        background: rgba(255,255,255,.5) url(../images/loader.gif) center center no-repeat;
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow: hidden;
        position: absolute;
        z-index: 100;
    }

    &--no-spinner:before {
        background-image: none;
    }

    &--no-background:before {
        background-color: transparent;
    }
}
