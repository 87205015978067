
.modal {
    position: absolute;
    width: 435px;
    max-width: 100%;
    z-index: 100;
    transition:opacity 0.5s ease;
    display:none;

    .modal__inner {
        position:relative;
        padding: 10px;
        background:#fff;
        width:400px;
        max-width: 100%;
        border:1px solid black;
        box-sizing: border-box;
    }

    
      &--image {
        width: 100%;
        max-width: 1000px !important;
        min-width: 100px;
        min-height: 100px;

        &:before {
            background-color: transparent;
        }

        .modal__inner {
            width: auto;
            padding: 0;

            // .modal__close {
            //     right:-5px;
            //     top:-5px;
            // }
        }
    }
}


.item-list-popup {
    position:absolute;
    z-index:2;
    width:250px;

    box-shadow:2px 2px 10px -2px;
    left:0; right:0;
    top:0;
    margin:0 auto;

    @include grid-media-query("palm") {
        position:relative;
        width:100%;
        margin:$spacing-half 0;
    }
}
