
.item-product {
    background-color: white;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 55px;
    position: relative;

    .carousel & {
        width: 183px;

        @include grid-media-query(palm) {
           width:100%;
        }
    }
    
    &__content,
    &__controls {
        box-sizing: border-box;
    }

    &__content {
        padding-left: $spacing-large;
        padding-right: $spacing-large;
    }
    
    &__controls {
        bottom: 0;
        padding-left: $spacing;
        padding-right: $spacing;
        padding-bottom: $spacing-large;
        position: absolute;
        width: 100%;
    }

    &__info {
        @include font-size(15px, 24px);
    }

    &__offer {
        background: url(../images/thriftyfoods-light-background.png) #fff;
        padding: $spacing-double $spacing;
        .price {
            @include font-size(26px, 32px);
            font-weight: 900;
        }
    }
}