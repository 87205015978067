// =Home Page
// --------------------------------------------------

.page-home .breadcrumb {
  @include remove();
}

.page-home .main-nav {
  margin-bottom: 25px;
}

.steps {
  margin-top: 2em;
}

.step {
  margin-bottom: 2em;
}

.step hr.strong {
  margin-top: 10px;
  margin-bottom: 20px;
}

@include grid-media-query('portable-up') {
  .categories-container {
    list-style: none;
    margin: 0;
    padding: 0 !important;

    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: stretch;

    li:before {
      display: none;
    }

    // @include clearfix();
  }
  .categories-item {
    list-style: none !important;
    display: block;
    // float: left;
    margin-top: 0 !important;
    margin-right: 30px;
    margin-bottom: 30px;
    background-color: #fff;
    background-repeat: no-repeat;

    // &:last-child {
    //   margin-right: 0;
    // }

    @each $category in $categories {
      &.#{$category} {
        background-image: url(../images/#{$category}.jpg);
      }
    }

    a {
      font-size: 20px;
      display: block;
      width: 136px;
      padding: 168px 22px 18px 22px;
      // color: #000 !important;
      color: $color-main;
      text-decoration: none;
      font-family: $font-alt;
      font-weight: normal;
      text-transform: uppercase;
      line-height: 1.4;
    }
  }
}
