
.period-schedule {
    position: relative;
    box-sizing: border-box;
    border: 1px solid $color-warm-grey;

    tr + tr {
        border-top: 1px solid $color-warm-grey;
    }

    th,
    td {
        box-sizing: border-box;
        border-left: 1px solid $color-warm-grey;
        border-right: 1px solid $color-warm-grey;
    }

    .table--fixed {
        width: 10%;
        box-sizing: border-box;

        tr + tr td {
            border-top: 1px solid $color-warm-grey;
        }

        th,
        td {
            border-right: 1px solid $color-warm-grey;
            background-color: #fff;
            border-left: none;
        }

        th {
            height: 100px;
        }

        td {
            height: 70px;
        }

    }

    .table--scrolling {
        width: 90%;
        box-sizing: border-box;
        overflow: hidden;


        table {
            transition: all 0.5s ease;
            margin-left: 0;
            table-layout: fixed;
        }

        .table--2x {
            width: 200%;
        }
        .table--3x {
            width: 300%;
        }
        .table--4x {
            width: 400%;
        }

        th,
        .row-header td
         {
            height: 50px;
        }
    }


    td {
        box-sizing: border-box;
        height: 35px;

        transition: background-color .15s ease;

        &[rowspan="2"] span:before{
            @include font-size(26px);
        }

        > a {
            height: 100%;
            width: 100%;
            z-index: 2;
        }

        &.is-available {
            background-color: #fff;
            color: $color-main;
        }
        &.is-active {
            background-color: $color-main;
            color: #fff;
        }
        &.is-available:hover {
            background-color: $color-lime-green;
            color: #fff;
            cursor: pointer;
        }
        &.is-available.is-active:hover {
            background-color: $color-tfs-green;
            color: #fff;
        }
        &.is-unavailable {
            background-color: $color-red;
            color: #fff;
        }
        &.is-full {
            background-color: $color-gray;
        }
    }

    .row-header{
        th {
            border: 1px solid $color-warm-grey;
            box-sizing: border-box;
            background-color: #fff;
            &:first-child {
                border-left: 0;
            }
        }

        &:first-child th {
            border-top: 0;
            border-bottom: 0;
        }
    }

}
