.warning-box {
	border: 1px solid #f0ad4e;
	padding: 20px 20px 5px 20px;
	margin: 15px 0;
	background-color: #f2f2f2;
	min-height: 60px;

	.icon--warning-circle { 
		color: #f0ad4e; 
		position: relative;
		top: 5px;
	}

}