.mobile-toggler,
.portable-toggler {
    h2 {
        &:before{
            display: none;
            @extend %icons;
        }
        @extend %icon--arrow-down;
    }

    &.is-open {
        h2 {
            @extend %icon--arrow-up;
        }
    }
}

.mobile-toggler {
    @include grid-media-query("palm") {
        overflow: hidden;
        h2 {
            cursor: pointer;
            position: relative;

            &:before {
                display: inline-block;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .mobile-toggler__content {
            display: none;
        }
        &.is-open {

            .mobile-toggler__content {
                display: block;
            }
        }
    }
}

.portable-toggler {
    @include grid-media-query("portable") {
        overflow: hidden;
        h2 {
            cursor:pointer;
            position:relative;

            &:before {
                display: inline-block;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .mobile-toggler__content {
            display: none;
        }
        &.is-open {
            .mobile-toggler__content {
                display: block;
            }
        }
    }
}
