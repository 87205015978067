.main-nav {
  position: relative;
  z-index: 20;
  @include grid-media-query('palm') {
    margin-bottom: $spacing-double;
  }

  .nav {
    font-size: 0;
    background: white;
    text-align: center;

    @include grid-media-query('palm') {
      display: none;
      text-align: left;
    }

    > li {
      @include font-size($base-font-size);
      padding: 0 $spacing-large;
      position: relative;

      @include grid-media-query('palm') {
        margin: 0;
        display: block;
        + li {
          border-top: solid 1px $color-maize;
        }
      }

      > a {
        @include font-size(14px, 45px);
        font-weight: 900;
        padding: $spacing $spacing-half;
        text-transform: uppercase;

        @include grid-media-query('palm') {
          display: block;
          @include font-size(16px, 45px);
        }
      }

      &:hover {
        background-color: $color-maize;

        > a {
          // color: white;
          text-decoration: none;
        }
      }
    }
  }

  &.is-open {
    .nav {
      display: block;
    }
  }

  a {
    color: $color-warm-black;
  }

  &__submenu {
    background: #fff;
    background: rgba(255, 255, 255, 0.98);
    border: 1px solid $color-warm-grey;
    border-top: 0;
    display: none;
    left: -$spacing;
    padding: $spacing-double $spacing-and-half;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    z-index: 150;

    @include grid-media-query('palm') {
      display: none !important;
    }

    @include grid-media-query('portable') {
      display: none !important;
    }

    .submenu__col {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
      width: 160px;

      & + .submenu__col {
        margin-left: $spacing-double;
      }

      li {
        margin-bottom: $spacing-large;
      }

      a {
        @include font-size(13px, 16px);
        display: block;
        font-weight: bold;
        text-align: left;

        &:hover {
          color: $color-main;
          text-decoration: underline;
        }
      }
    }

    li:hover > & {
      display: block;
    }
  }

  &__controls {
    margin: 0 $spacing;
  }
}
