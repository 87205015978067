/**
 * Responsive video wrapper
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 * http://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
 */
.video {
    position: relative;
    padding-bottom: 56.25%; /* video ratio */
    height: 0;
}
.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
.carousel .featured-video {
	width: 600px;
}*/