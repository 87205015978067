.logos {
    position: relative;
    z-index: 1;
    @include grid-media-query("palm") {
		margin-bottom: $spacing-double;
	}
}

.logo {
    width: 175px;
}