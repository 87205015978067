.simple-slide {
    display: block;
    width: 100%;

    &__slides {
        padding-bottom: $spacing;
        position: relative;
        width: 100%;

        li {
            opacity: 0;
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            z-index: 1;
        }
        li.is-active {
            opacity: 1;
            z-index: 2;
        }
    }

    &__controls {
        padding-bottom: $spacing;
    }
}
