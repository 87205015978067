/*------------------------------------*\
    #RESET
\*------------------------------------*/

/**
 * As well as using normalize.css, it is often advantageous to remove all
 * margins from certain elements.
 */
body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr, figure {
    margin:  0;
    padding: 0;
}

ul {
    list-style: none;
}
