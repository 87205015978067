.pagination {
    .nav {
        border-radius: 4px;
        border: 1px solid $color-main;
        display: inline-block;
        font-size: 0;
        text-align: center;
        vertical-align: top;
        > li {
            vertical-align: top;
            & + li {
                border-left: 1px solid $color-main;
            }
            > a {
                @include font-size(12px, 36px);
                padding-top: 2px;
                vertical-align: top;
                width: 24px;
                &:hover,
                &:focus {
                    background-color: $color-secondary;
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }

    .is-disabled,
    .aspNetDisabled {
        color: $color-warm-grey;
        cursor: default;
    }

    .is-active {
        background-color: $color-main !important;
        color: #fff !important;
        cursor: default;
        font-weight: bold;
    }
}