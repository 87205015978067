.debug {
    .grid__item {
        background: rgba(0,0,0,0.7);
    }
}

.debug-box {
    background: rgba(red($color-main), green($color-main), blue($color-main), 1);
    display: block;
    font-size: 12px;
    line-height: 4;
    margin-bottom: $spacing;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;

    &--large {
        line-height: 16;
    }

    &--x-large {
        line-height: 24;
    }

    &--full {
        display: block;
        height: 100%;
    }
}

.debug-grid {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    height: 100%;

    .debug-box {
        background: rgba(255,0,0,.4);
    }
    * {
        height: 100%;
    }
}



.sg-colors {
    li {
        display: block;
        float: left;
        font-size: 11px;
        width: 100px;
        height: 200px;
    }
    .sg-swatch {
        display: block;
        height: 100px;
        width: 100%;
    }
    .sg-label {
        display: block;

    }
}



.debug-mqs {
    position: fixed;
    top: 0;
    left: 0;
    opacity: .9;
}
.debug-mq {
    text-align: center;
    display: inline-block;
    background: #ddd;
    line-height: 2;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: $gutter;
}
.debug-mq--palm {
    @include grid-media-query('palm') {
        background: #f66;
    }
}
.debug-mq--portable {
    @include grid-media-query('portable') {
        background: #f66;
    }
}
.debug-mq--desk {
    @include grid-media-query('desk') {
        background: #f66;
    }
}
