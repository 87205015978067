@mixin font-size($font-size, $line-height: true) {

    // modded to make lh relative, so will work on print styles
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;

    @if $line-height == true {
        // line-height: $font-size * ($base-line-height / $base-font-size);
        line-height: $base-line-height / $base-font-size;
    }

    // if numeric
    @elseif (($line-height / $line-height) == 1) {
        // line-height: $line-height;
        line-height: $line-height / $font-size;
    }
}


@mixin border-radius($radius) {
//    behavior: url(/assets/scripts/PIE.htc);
    border-radius: $radius;
}

@mixin media-query($query) {
    @media only screen and #{$query} {
        @content
    }
}
