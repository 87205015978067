.carousel {
    text-align: center;

    .owl-page{
        display: none;
    }

    .owl-arrow {
        display: inline-block;
        zoom: 1;
        @include font-size(15px, 34px);
        background: $color-main;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        margin: 0 22px;
        padding: 0;
        width: 34px; height: 34px;
        margin-bottom: $spacing-double;
        &:hover,
        &:focus {
            background: darken($color-main, 10%);
        }
    }

    &__item {
        display: inline-block;
        margin: 0 0 $spacing-double  $spacing-double;
        text-align: left;
        vertical-align: top;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        @include grid-media-query(palm) {
            margin-left: 0;
            width:100%;
        }
        
        .owl-item & {
            float: none;
            display: inline-block;
            margin-right: $spacing;
            margin-left: $spacing;
            > .grid__item {
                width: auto;
            }   
        }
    }
}