// overrides for main nav
// see modules/nav & modules/main-nav

@include grid-media-query(portable-up) {
  // .main-nav ul.nav {
  //   text-align: left;
  // }

  // .main-nav .nav li:first-child {
  //   margin-left: 0.5em;
  // }

  .main-nav .nav li.tf-site {
    // float: right;
    // text-align: right;
    // margin-right: 0.5em;

    a {
      color: $color-tf-dark-green;

      &:hover,
      &:active {
        color: #fff;
      }
    }
  }
}

@include grid-media-query(portable-up) {
  .main-nav .nav {
    > li {
      padding: 0 8px;
      padding: 0;

      > a {
        // @include font-size(12px, 45px);
        // box-sizing: border-box;
        padding: 5px 2px;
      }
    }
  }
}

@include grid-media-query(desk) {
  // .main-nav .nav li:first-child {
  //   margin-left: 1.5em;
  // }

  // .main-nav .nav li:last-child {
  //   margin-right: 1.5em;
  // }
}

@include grid-media-query(palm) {
  .main-nav {
    position: relative;
  }

  .main-nav .nav {
    display: block;
    position: absolute;
    left: -9999px;
  }

  .main-nav.is-open .nav {
    position: static;
    left: auto;
  }
}

@include grid-media-query(portable-up) {
  .main-nav .nav {
    border: 1px solid $color-maize;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    > li {
      text-align: left;
      display: block;
      flex-basis: 25%;

      // border: 1px solid red;
    }

    > li > a {
      font-size: 0.8em;
      padding: 5px 15px;
      width: 100%;
    }
  }
}

@include grid-media-query(desk) {
  .main-nav .nav {
    // border: 1px solid $color-maize;
    > li {
      flex-basis: 16.66666%;
    }
    > li > a {
      font-size: 0.85em;
    }
  }
}
