// =Cart
// --------------------------------------------------

.shopping-cart {
  table {
    max-width: 800px;
  }

  .item-quantity .numeric {
    box-sizing: border-box;
    display: inline-block;
    font-weight: bold;
    border: 1px solid $color-tf-dark-green;
    // border-radius: 5px;
    text-align: center;
    width: 33px;
    height: 33px;
    padding-top: 8px;
    line-height: 1;
  }

  .item-label {
    color: $color-tf-dark-green;
  }

  table tr:hover td.item-label {
    color: $color-tf-dark-green !important;
  }

  .item-total {
    font-weight: bold;
    white-space: nowrap;
  }

  @include grid-media-query('portable-up') {
    .item-numeric {
      text-align: right;
    }

    .item-full-total {
      border-top: solid 1px #000;
    }

    th.item-total {
      border-bottom: dotted 1px $color-secondary;
    }
  }

  td {
  }

  .submit {
    margin-top: 1em;
    @include clearfix();
  }

  input[type='submit'].update {
    float: left;
  }

  input[type='submit'].checkout {
    float: right;
  }
}
