
.additionnal-info {
    overflow: visible;
    position: relative;

    .content {
        display: none;
        left: 0; top: auto;
        position: absolute;
        z-index: 2;
    }

    &:hover {

        .content {
            display: block;
        }
    }

}
