// Responsive images
img {
//    max-width: 100%;
//    height: auto;
}

img.fluid {
    display: block;
    width: 100%;
    height: auto;
}
